import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  TitleContent,
  Visit,
  WorkedWith,
} from "../components/parts"

const AugmentedReality = () => (
  <Layout title="BlockPunk">
    <Title heading="BlockPunk" sub="Anime marketplace over blockchain" />
    <Overview
      project="blockpunk"
      text={[
        "BlockPunk started by selling exclusive e-collectibles from anime studios in Japan.",
        "I helped them write the front end for their first web app, integrating with Ethereum via Metamask to set up a marketplace.",
      ]}
    />
    <Visit
      websites={[
        {
          url: "https://blockpunk.net/en/collection/eb",
          label: '"Buy" "me" on blockchain',
        },
      ]}
    />
    <TitleContent title="Marketplace">
      <img
        src="/images/blockpunk/1.png"
        className="bordered"
        style={{ marginBottom: 12 }}
        alt="screenshot"
      />
      <img
        src="/images/blockpunk/2.png"
        className="bordered"
        style={{ marginBottom: 12 }}
        alt="screenshot"
      />
      <img
        src="/images/blockpunk/3.png"
        className="bordered"
        style={{ marginBottom: 12 }}
        alt="screenshot"
      />
    </TitleContent>
    <Toolset project="blockpunk" />
    <Visit project="blockpunk" />
    <WorkedWith project="blockpunk" />
  </Layout>
)

export default AugmentedReality
